const constants = {
  url: {
    btuProtocolAbout: 'https://btu-protocol.com/about',
    mail: 'info@btu-protocol.com'
  },
  sendmail: {
    url: 'https://btu-protocol.com/srv/',
    script: 'card.php',
  },
  social: [
    {
      name: 'Facebook',
      file: 'facebook.png',
      url: 'https://www.facebook.com/btuprotocol/',
    },
    {
      name: 'LinkedIn',
      file: 'linkedin.svg',
      url: 'https://www.linkedin.com/company/btu-protocol/',
    },
    {
      name: 'Medium',
      file: 'medium.png',
      url: 'https://medium.com/@BTUProtocolTeam/latest',
    },
    {
      name: 'Telegram',
      file: 'telegram.svg',
      url: 'https://t.me/btucommunity',
    },
    {
      name: 'Twitter',
      file: 'twitter.svg',
      url: 'https://twitter.com/BtuProtocol',
    },
    {
      name: 'YouTube',
      file: 'youtube.png',
      url: 'https://www.youtube.com/channel/UC4TU0cH82u0kLeEomf26Z0g/',
    },
    {
      name: 'Instagram',
      file: 'instagram.png',
      url: 'https://www.instagram.com/btuprotocol/',
    },
    /*
    {
      name: 'GitHub',
      file: 'github.png',
      url: 'https://github.com/btuprotocol/',
    },
    */
  ],
  languages: ['en', 'fr', 'kr', 'ru', 'zh', 'de', 'it', 'es', 'tr', 'pt', 'id', 'ja'],
}

export default constants
