import React from 'react'
import {connect} from "react-redux"

import $ from 'jquery'
import WOW from 'wow.js'
import {withTranslation} from "react-i18next"

import Header from '../components/header/header'
import Intro from '../components/intro/intro'
import Tasks from '../components/tasks/tasks'
import Section from '../components/section/section'
import Reviews from '../components/reviews/reviews'
import Form from './form.jsx'
import Banner from '../components/banner/banner'
import Footer from '../components/footer/footer'

import introImage from '../assets/img/Group 17.png'
import taskImage1 from '../assets/img/Group 18.png'
import taskImage2 from '../assets/img/Group 19.png'
import taskImage3 from '../assets/img/Illu_etape2.png'
import sectionImage1 from '../assets/img/Rectangle 28.jpg'
import sectionImage2 from '../assets/img/Rectangle 28.1.jpg'
import sectionImage3 from '../assets/img/Rectangle 28.3.jpg'
import sectionImage4 from '../assets/img/Rectangle 28.2.jpg'
import constants from '../config/constants';

class Home extends React.Component {
  componentDidMount() {
    // WOW animations initialization
    new WOW().init()

    // Handle back to top button click
    $('.btn-scroll-top').on('click', function () {
      $('body,html').animate({scrollTop: 0}, 800)
    })

    if(window.innerWidth < 1192) {
      // Mobile Screen
      $('nav').removeClass('bg-white')
      $('nav').fadeIn().addClass('bg-light')
    }

    // Scroll header and toggle fixed position
    $(window).scroll(function () {
      let scrolledHeight = $(this).scrollTop()

      // Display back to top button
      if (scrolledHeight >= 100) {
          $('.btn-scroll-top').fadeIn(900).removeClass('d-none')
      } else if (!$('.btn-scroll-top').hasClass('d-none')) {
          $('.btn-scroll-top').fadeOut(900).addClass('d-none')
      }

      // Toggle fixed position header section
      if(window.innerWidth >= 1192) {
          // Desktop Screen
          if (scrolledHeight >= 50) {
              $('nav').removeClass('bg-white')
              $('nav').fadeIn().addClass('bg-light')
          } else {
              $('nav').removeClass('bg-light')
              $('nav').fadeIn().addClass('bg-white')
          }
      }
      else
      {
          $('nav').removeClass('bg-white')
          $('nav').fadeIn().addClass('bg-light')
      }
    })

    /*
    document.addEventListener('mousewheel', (event) => { event.preventDefault() }, { passive: true })
    document.addEventListener('touchstart', (event) => { event.preventDefault() }, { passive: true })
    document.addEventListener('touchmove', (event) => { event.preventDefault() }, { passive: true })
    document.addEventListener('wheel', (event) => { event.preventDefault() }, { passive: true })
    */
  }

  /**
   * Fonction appelée au clic sur le bouton de contact
   */
  onClickContact = () => {
    window.open('mailto:' + constants.url.mail,'_blank');
  }

  /**
   * Scrolle vers le formulaire
   */
  scrollToForm = () => {
    let target = $('#form')
    $('html, body').stop().animate({ scrollTop: $(target).offset().top - 50 }, 600)
  }

  render() {
    const {
      t,
      dispatch,
      /*
      userLocale,
      isLoading,
      */
    } = this.props

    return (
      <React.Fragment>
        <Header useSuspense={false} />

        <Intro
          id='home'
          title={(
            <div>
              {t('intro.title_1')}<br />
              {t('intro.title_2')}
            </div>
          )}
          text={t('intro.text')}
          button={t('intro.button')}
          onClick={this.scrollToForm}
          image={introImage}
        />

        <Tasks
          title={t('how.title')}
          tasks={[
            {
              image: taskImage1,
              title: t('how.content.0.title'),
              text: t('how.content.0.text'),
            },
            {
              image: taskImage2,
              title: t('how.content.1.title'),
              text: t('how.content.1.text'),
            },
            {
              image: taskImage3,
              title: t('how.content.2.title'),
              text: t('how.content.2.text'),
            },
          ]}
        />

        <Section
          image={sectionImage1}
          title={t('section_1.title')}
          content={t('section_1.text')}
        />

        <Section
          image={sectionImage2}
          title={t('section_2.title')}
          content={t('section_2.text')}
          imageRight
        />

        <Section
          image={sectionImage3}
          title={t('section_3.title')}
          content={t('section_3.text')}
        />

        <Section
          image={sectionImage4}
          title={t('reviews.title')}
          content={(
            <Reviews
              content={[
                {
                  "text": t('reviews.content.0.text'),
                  "name": t('reviews.content.0.name')
                },
                {
                  "text": t('reviews.content.1.text'),
                  "name": t('reviews.content.1.name')
                },
                {
                  "text": t('reviews.content.2.text'),
                  "name": t('reviews.content.2.name')
                },
              ]}
            />
          )}
          imageRight
        />

        <Form
          t={t}
          useSuspense={false}
          dispatch={dispatch}
        />

        <Banner
          text={t('contact.text')}
          button={t('contact.button')}
          onClick={this.onClickContact}
        />

        <Footer
          t={t}
          useSuspense={false}
        />
        <div className="btn-scroll-top d-none text-decoration-none">
          <i className="la la-long-arrow-up la-2x"></i>
        </div>
      </React.Fragment>
    )
  }
}

Home.propTypes = {}

Home.defaultProps = {}

const mapStateToProps = (state) => {
  return {
    userLocale: state.btu.userLocale,
    isOpenModal: state.btu.isOpenModal,
    modalAction: state.btu.modalAction,
    isLoading: state.btu.isLoading,
  }
}

Home = connect(mapStateToProps, null)(Home)
export default withTranslation()(Home)