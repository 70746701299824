import React from 'react'
import PropTypes from 'prop-types'
import {Container, Row, Col, Button} from "reactstrap"

import {PulseLoader} from 'react-spinners'
import {  css } from '@emotion/core'

import TextInput from '../components/form/textInput'
import EmailInput from '../components/form/emailInput'
import TextAreaInput from '../components/form/textAreaInput'

import constants from '../config/constants'

import './form.css'

const loaderStyle = css`
    display: block
    margin: 0 0 0 10px
    padding: 0
    border-color: red
`

class Form extends React.Component {
  state = {
    firstName: {
      value: '',
      error: true,
    },
    lastName: {
      value: '',
      error: true,
    },
    email: {
      value: '',
      error: true,
    },
    phone: {
      value: '',
      error: true,
    },
    message: {
      value: '',
      error: false,
    },
    displayErrors: false,
    waiting: false,
    done: false,
  }

  /**
   * Fonction appelée lors de la modification d'un champ de formulaire
   *
   * @param {string} field - Nom du champ
   * @param {Object} value - Valeur du champ
   */
  onChange = (field, value) => {
    this.setState({[field]: value})
  }

  /**
   * Fonction appelée au clic sur le bouton "Envoyer"
   */
  onSend = () => {
    const {
      firstName,
      lastName,
      email,
      phone,
      message,
    } = this.state
    
    if (!firstName.error && !lastName.error && !email.error && !phone.error) {
      // Aucune erreur de champ : on envoie le message
      this.setState({
        displayErrors: false,
        waiting: true,
      })

      fetch(constants.sendmail.url + constants.sendmail.script, {
        method: 'POST',      
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          Contact: {
            firstName: firstName.value,
            lastName: lastName.value,
            email: email.value,
            phone: phone.value,
            message: message.value,
          }
        })
      })
      .then(function (response) {
        return response.json()
      })
      .then(data => {
        if (data.result) {
          this.setState({
            waiting: false,
            done: true,
          })
        }
        else {
          this.setState({
            waiting: false,
            done: false,
          })
        }
      })
      .catch(function (error) {
        console.log(error)
        this.setState({
          waiting: false,
          done: false,
        })
      })

    } else {
      // L'un des champ comporte une erreur
      this.setState({ displayErrors: true })

      // Recherche du champ en erreur et focus dessus
      if (phone.error) {
        document.getElementById('form_phone').focus()
      }
      if (email.error) {
        document.getElementById('form_email').focus()
      }
      if (lastName.error) {
        document.getElementById('form_lastName').focus()
      }
      if (firstName.error) {
        document.getElementById('form_firstName').focus()
      }
    }
  }

  render() {
    const {
      t,
    } = this.props

    const {
      displayErrors,
      waiting,
      done,
    } = this.state

    return (
      <section>
        <Container className="form-container">
          <div id="form" className="form-anchor" />
          <Row>
            <Col className="form-title">
              <h1 className="section-title text-center">{t('form.title')}</h1>
            </Col>
          </Row>
          <Row className="form-main-container p-lg-3 p-1">
            <Col className="d-flex align-items-center justify-content-center">
              <div className="form-main">
                { !done
                ? (
                  <form>
                    <Row className="p-0">
                      <Col lg={{size: 6}}>
                        <TextInput
                          label={t('form.first_name.label')}
                          placeholder={t('form.first_name.placeholder')}
                          fieldName='firstName'
                          onChange={this.onChange}
                          displayErrors={displayErrors}
                          errorMessage={t('form.first_name.error')}
                          emptyMessage={t('form.first_name.empty')}
                        />
                      </Col>
                      <Col lg={{size: 6}}>
                        <TextInput
                          label={t('form.last_name.label')}
                          placeholder={t('form.last_name.placeholder')}
                          fieldName='lastName'
                          onChange={this.onChange}
                          displayErrors={displayErrors}
                          errorMessage={t('form.last_name.error')}
                          emptyMessage={t('form.last_name.empty')}
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <EmailInput
                          label={t('form.email.label')}
                          placeholder={t('form.email.placeholder')}
                          fieldName='email'
                          onChange={this.onChange}
                          displayErrors={displayErrors}
                          errorMessage={t('form.email.error')}
                          emptyMessage={t('form.email.empty')}
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <TextInput
                          label={t('form.phone.label')}
                          placeholder={t('form.phone.placeholder')}
                          fieldName='phone'
                          onChange={this.onChange}
                          displayErrors={displayErrors}
                          errorMessage={t('form.phone.error')}
                          emptyMessage={t('form.phone.empty')}
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <TextAreaInput
                          label={t('form.message.label')}
                          placeholder={t('form.message.placeholder')}
                          fieldName='message'
                          onChange={this.onChange}
                          displayErrors={false}
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col className="d-flex align-items-center justify-content-center">
                        { waiting
                        ? (
                          <PulseLoader
                            css={loaderStyle}
                            sizeUnit="px"
                            size={12}
                            color="#5BACE2"
                            loading={true}
                          />
                          )
                        : (
                          <Button
                            className={'btn btn-primary d-inline-flex align-items-center justify-content-between'}
                            onClick={this.onSend}
                          >
                            {t('form.send')}
                          </Button>
                        )}
                      </Col>
                    </Row>
                  </form>
                  )
                : (
                  <div className="form-response">
                    {t('form.message_ok')}
                  </div>
                )}
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    )
  }
}

Form.propTypes = {
  t: PropTypes.instanceOf(Object).isRequired,
}

Form.defaultProps = {
}

export default Form